import React from 'react'
import { Col, Image, ResponsiveEmbed, Row } from 'react-bootstrap'

import { AuthorContentDto } from '../generatedApis/models'

import styles from './AuthorBlock.module.scss'
import Markdown from './Markdown'

const AuthorBlock: React.FC<{
    author: AuthorContentDto
    onSelect: (url: string) => void
}> = ({ author, onSelect }) => (
    <div key={author.id} className={styles.authorBlock}>
        <h3>{author.data.name.iv}</h3>
        <Row>
            <Col md={5}>
                <Markdown source={author.data.introduction.iv!} />
            </Col>
            <Col md={7}>
                <Row className={styles.thumbnails}>
                    {author.data.photos.iv!.map((id) => (
                        <Col key={id} md={4}>
                            <ResponsiveEmbed aspectRatio="4by3">
                                <Image
                                    rounded={true}
                                    src={`${process.env.REACT_APP_API_URL}/api/assets/fotoblazinec/${id}`}
                                    onClick={() =>
                                        onSelect(
                                            `${process.env.REACT_APP_API_URL}/api/assets/fotoblazinec/${id}`
                                        )
                                    }
                                />
                            </ResponsiveEmbed>
                        </Col>
                    ))}
                </Row>
            </Col>
        </Row>
    </div>
)

export default AuthorBlock
