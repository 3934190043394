/* tslint:disable */
/* eslint-disable */
/**
 * Squidex API for fotoblazinec App
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 4.0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DocumentsContentProperty,
    DocumentsContentPropertyFromJSON,
    DocumentsContentPropertyFromJSONTyped,
    DocumentsContentPropertyToJSON,
} from './';

/**
 * 
 * @export
 * @interface DocumentsDto
 */
export interface DocumentsDto {
    /**
     * 
     * @type {DocumentsContentProperty}
     * @memberof DocumentsDto
     */
    content: DocumentsContentProperty;
}

export function DocumentsDtoFromJSON(json: any): DocumentsDto {
    return DocumentsDtoFromJSONTyped(json, false);
}

export function DocumentsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'content': DocumentsContentPropertyFromJSON(json['content']),
    };
}

export function DocumentsDtoToJSON(value?: DocumentsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'content': DocumentsContentPropertyToJSON(value.content),
    };
}


