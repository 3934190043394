import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Route, Switch } from 'react-router'
import { BrowserRouter as Router } from 'react-router-dom'

import './App.scss'
import styles from './common.module.scss'
import Footer from './components/Footer'
import Navbar from './components/Navbar'
import AuthorsPage from './pages/AuthorsPage'
import DocumentsPage from './pages/DocumentsPage'
import EditionPage from './pages/EditionPage'
import HomePage from './pages/HomePage'
import SchedulePage from './pages/SchedulePage'
import {
    AUTHORS_PATH,
    DOCUMENTS_PATH,
    EDITION_PATH,
    HOME_PAGE_PATH,
    SCHEDULE_PATH,
} from './paths'

const App = () => {
    return (
        <Router>
            <Navbar />
            <Content />
            <Footer />
        </Router>
    )
}

const Content = () => {
    return (
        <Container className={styles.contentWrapper}>
            <Row>
                <Col>
                    <Switch>
                        <Route component={EditionPage} path={EDITION_PATH} />
                        <Route
                            component={HomePage}
                            exact={true}
                            path={HOME_PAGE_PATH}
                        />
                        <Route component={SchedulePage} path={SCHEDULE_PATH} />
                        <Route component={AuthorsPage} path={AUTHORS_PATH} />
                        <Route
                            component={DocumentsPage}
                            path={DOCUMENTS_PATH}
                        />
                    </Switch>
                </Col>
            </Row>
        </Container>
    )
}

export default App
