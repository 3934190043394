/* tslint:disable */
/* eslint-disable */
/**
 * Squidex API for fotoblazinec App
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 4.0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AuthorEditionProperty,
    AuthorEditionPropertyFromJSON,
    AuthorEditionPropertyFromJSONTyped,
    AuthorEditionPropertyToJSON,
    AuthorIntroductionProperty,
    AuthorIntroductionPropertyFromJSON,
    AuthorIntroductionPropertyFromJSONTyped,
    AuthorIntroductionPropertyToJSON,
    AuthorNameProperty,
    AuthorNamePropertyFromJSON,
    AuthorNamePropertyFromJSONTyped,
    AuthorNamePropertyToJSON,
    AuthorPhotosProperty,
    AuthorPhotosPropertyFromJSON,
    AuthorPhotosPropertyFromJSONTyped,
    AuthorPhotosPropertyToJSON,
} from './';

/**
 * 
 * @export
 * @interface AuthorDto
 */
export interface AuthorDto {
    /**
     * 
     * @type {AuthorNameProperty}
     * @memberof AuthorDto
     */
    name: AuthorNameProperty;
    /**
     * 
     * @type {AuthorEditionProperty}
     * @memberof AuthorDto
     */
    edition: AuthorEditionProperty;
    /**
     * 
     * @type {AuthorIntroductionProperty}
     * @memberof AuthorDto
     */
    introduction: AuthorIntroductionProperty;
    /**
     * 
     * @type {AuthorPhotosProperty}
     * @memberof AuthorDto
     */
    photos: AuthorPhotosProperty;
}

export function AuthorDtoFromJSON(json: any): AuthorDto {
    return AuthorDtoFromJSONTyped(json, false);
}

export function AuthorDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthorDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': AuthorNamePropertyFromJSON(json['name']),
        'edition': AuthorEditionPropertyFromJSON(json['edition']),
        'introduction': AuthorIntroductionPropertyFromJSON(json['introduction']),
        'photos': AuthorPhotosPropertyFromJSON(json['photos']),
    };
}

export function AuthorDtoToJSON(value?: AuthorDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': AuthorNamePropertyToJSON(value.name),
        'edition': AuthorEditionPropertyToJSON(value.edition),
        'introduction': AuthorIntroductionPropertyToJSON(value.introduction),
        'photos': AuthorPhotosPropertyToJSON(value.photos),
    };
}


