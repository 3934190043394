/* tslint:disable */
/* eslint-disable */
/**
 * Squidex API for fotoblazinec App
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 4.0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ContactEmailProperty,
    ContactEmailPropertyFromJSON,
    ContactEmailPropertyFromJSONTyped,
    ContactEmailPropertyToJSON,
    ContactFacebookLinkProperty,
    ContactFacebookLinkPropertyFromJSON,
    ContactFacebookLinkPropertyFromJSONTyped,
    ContactFacebookLinkPropertyToJSON,
} from './';

/**
 * 
 * @export
 * @interface ContactDto
 */
export interface ContactDto {
    /**
     * 
     * @type {ContactEmailProperty}
     * @memberof ContactDto
     */
    email: ContactEmailProperty;
    /**
     * 
     * @type {ContactFacebookLinkProperty}
     * @memberof ContactDto
     */
    facebookLink: ContactFacebookLinkProperty;
}

export function ContactDtoFromJSON(json: any): ContactDto {
    return ContactDtoFromJSONTyped(json, false);
}

export function ContactDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': ContactEmailPropertyFromJSON(json['email']),
        'facebookLink': ContactFacebookLinkPropertyFromJSON(json['facebookLink']),
    };
}

export function ContactDtoToJSON(value?: ContactDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': ContactEmailPropertyToJSON(value.email),
        'facebookLink': ContactFacebookLinkPropertyToJSON(value.facebookLink),
    };
}


