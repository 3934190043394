/* tslint:disable */
/* eslint-disable */
/**
 * Squidex API for fotoblazinec App
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 4.0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EditionDto,
    EditionDtoFromJSON,
    EditionDtoFromJSONTyped,
    EditionDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface EditionContentDto
 */
export interface EditionContentDto {
    /**
     * The id of the edition content.
     * @type {string}
     * @memberof EditionContentDto
     */
    id: string;
    /**
     * 
     * @type {EditionDto}
     * @memberof EditionContentDto
     */
    data: EditionDto;
    /**
     * 
     * @type {EditionDto}
     * @memberof EditionContentDto
     */
    dataDraft?: EditionDto;
    /**
     * The version of the edition.
     * @type {number}
     * @memberof EditionContentDto
     */
    version: number;
    /**
     * The date and time when the edition content has been created.
     * @type {Date}
     * @memberof EditionContentDto
     */
    created: Date;
    /**
     * The user that has created the edition content.
     * @type {string}
     * @memberof EditionContentDto
     */
    createdBy: string;
    /**
     * The date and time when the edition content has been modified last.
     * @type {Date}
     * @memberof EditionContentDto
     */
    lastModified: Date;
    /**
     * The user that has updated the edition content last.
     * @type {string}
     * @memberof EditionContentDto
     */
    lastModifiedBy: string;
    /**
     * The status of the content.
     * @type {string}
     * @memberof EditionContentDto
     */
    status: string;
}

export function EditionContentDtoFromJSON(json: any): EditionContentDto {
    return EditionContentDtoFromJSONTyped(json, false);
}

export function EditionContentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EditionContentDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'data': EditionDtoFromJSON(json['data']),
        'dataDraft': !exists(json, 'dataDraft') ? undefined : EditionDtoFromJSON(json['dataDraft']),
        'version': json['version'],
        'created': (new Date(json['created'])),
        'createdBy': json['createdBy'],
        'lastModified': (new Date(json['lastModified'])),
        'lastModifiedBy': json['lastModifiedBy'],
        'status': json['status'],
    };
}

export function EditionContentDtoToJSON(value?: EditionContentDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'data': EditionDtoToJSON(value.data),
        'dataDraft': EditionDtoToJSON(value.dataDraft),
        'version': value.version,
        'created': (value.created.toISOString()),
        'createdBy': value.createdBy,
        'lastModified': (value.lastModified.toISOString()),
        'lastModifiedBy': value.lastModifiedBy,
        'status': value.status,
    };
}


