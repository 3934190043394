/* tslint:disable */
/* eslint-disable */
/**
 * Squidex API for fotoblazinec App
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 4.0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ScheduleContentProperty
 */
export interface ScheduleContentProperty {
    /**
     * Invariant
     * @type {string}
     * @memberof ScheduleContentProperty
     */
    iv: string | null;
}

export function ScheduleContentPropertyFromJSON(json: any): ScheduleContentProperty {
    return ScheduleContentPropertyFromJSONTyped(json, false);
}

export function ScheduleContentPropertyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScheduleContentProperty {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'iv': json['iv'],
    };
}

export function ScheduleContentPropertyToJSON(value?: ScheduleContentProperty | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'iv': value.iv,
    };
}


