/* tslint:disable */
/* eslint-disable */
/**
 * Squidex API for fotoblazinec App
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 4.0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AboutContentDto,
    AboutContentDtoFromJSON,
    AboutContentDtoToJSON,
    AboutDto,
    AboutDtoFromJSON,
    AboutDtoToJSON,
    ChangeStatusDto,
    ChangeStatusDtoFromJSON,
    ChangeStatusDtoToJSON,
    ErrorDto,
    ErrorDtoFromJSON,
    ErrorDtoToJSON,
    InlineResponse2002,
    InlineResponse2002FromJSON,
    InlineResponse2002ToJSON,
} from '../models';

export interface ChangeAboutContentStatusRequest {
    id: string;
    changeStatusDto: ChangeStatusDto;
}

export interface CreateAboutContentRequest {
    aboutDto: AboutDto;
    publish?: boolean;
}

export interface DeleteAboutContentRequest {
    id: string;
}

export interface GetAboutContentRequest {
    id: string;
}

export interface PathAboutContentRequest {
    id: string;
    aboutDto: AboutDto;
}

export interface QueryAboutContentsRequest {
    $search?: string;
    $top?: number;
    $skip?: number;
    $orderby?: string;
    $filter?: string;
}

export interface UpdateAboutContentRequest {
    id: string;
    aboutDto: AboutDto;
}

/**
 * no description
 */
export class AboutApi extends runtime.BaseAPI {

    /**
     * Change status of About content.
     */
    async changeAboutContentStatusRaw(requestParameters: ChangeAboutContentStatusRequest): Promise<runtime.ApiResponse<AboutContentDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling changeAboutContentStatus.');
        }

        if (requestParameters.changeStatusDto === null || requestParameters.changeStatusDto === undefined) {
            throw new runtime.RequiredError('changeStatusDto','Required parameter requestParameters.changeStatusDto was null or undefined when calling changeAboutContentStatus.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("squidex-oauth-auth", ["squidex.apps.fotoblazinec.contents.about.update"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/content/fotoblazinec/about/{id}/status`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ChangeStatusDtoToJSON(requestParameters.changeStatusDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AboutContentDtoFromJSON(jsonValue));
    }

    /**
     * Change status of About content.
     */
    async changeAboutContentStatus(requestParameters: ChangeAboutContentStatusRequest): Promise<AboutContentDto> {
        const response = await this.changeAboutContentStatusRaw(requestParameters);
        return await response.value();
    }

    /**
     * Create a About content.
     */
    async createAboutContentRaw(requestParameters: CreateAboutContentRequest): Promise<runtime.ApiResponse<AboutContentDto>> {
        if (requestParameters.aboutDto === null || requestParameters.aboutDto === undefined) {
            throw new runtime.RequiredError('aboutDto','Required parameter requestParameters.aboutDto was null or undefined when calling createAboutContent.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.publish !== undefined) {
            queryParameters['publish'] = requestParameters.publish;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("squidex-oauth-auth", ["squidex.apps.fotoblazinec.contents.about.create"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/content/fotoblazinec/about/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AboutDtoToJSON(requestParameters.aboutDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AboutContentDtoFromJSON(jsonValue));
    }

    /**
     * Create a About content.
     */
    async createAboutContent(requestParameters: CreateAboutContentRequest): Promise<AboutContentDto> {
        const response = await this.createAboutContentRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete a About content.
     */
    async deleteAboutContentRaw(requestParameters: DeleteAboutContentRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteAboutContent.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("squidex-oauth-auth", ["squidex.apps.fotoblazinec.contents.about.delete"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/content/fotoblazinec/about/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a About content.
     */
    async deleteAboutContent(requestParameters: DeleteAboutContentRequest): Promise<void> {
        await this.deleteAboutContentRaw(requestParameters);
    }

    /**
     * Get a About content.
     */
    async getAboutContentRaw(requestParameters: GetAboutContentRequest): Promise<runtime.ApiResponse<AboutContentDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getAboutContent.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("squidex-oauth-auth", ["squidex.apps.fotoblazinec.contents.about.read"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/content/fotoblazinec/about/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AboutContentDtoFromJSON(jsonValue));
    }

    /**
     * Get a About content.
     */
    async getAboutContent(requestParameters: GetAboutContentRequest): Promise<AboutContentDto> {
        const response = await this.getAboutContentRaw(requestParameters);
        return await response.value();
    }

    /**
     * Patch a About content.
     */
    async pathAboutContentRaw(requestParameters: PathAboutContentRequest): Promise<runtime.ApiResponse<AboutContentDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling pathAboutContent.');
        }

        if (requestParameters.aboutDto === null || requestParameters.aboutDto === undefined) {
            throw new runtime.RequiredError('aboutDto','Required parameter requestParameters.aboutDto was null or undefined when calling pathAboutContent.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("squidex-oauth-auth", ["squidex.apps.fotoblazinec.contents.about.update"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/content/fotoblazinec/about/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: AboutDtoToJSON(requestParameters.aboutDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AboutContentDtoFromJSON(jsonValue));
    }

    /**
     * Patch a About content.
     */
    async pathAboutContent(requestParameters: PathAboutContentRequest): Promise<AboutContentDto> {
        const response = await this.pathAboutContentRaw(requestParameters);
        return await response.value();
    }

    /**
     * The squidex API the OData url convention to query data.   We support the following query options.  * **$top**: The $top query option requests the number of items in the queried collection to be included in the result. The default value is 20 and the maximum allowed value is 200. * **$skip**: The $skip query option requests the number of items in the queried collection that are to be skipped and not included in the result. Use it together with $top to read the all your data page by page.  * **$search**: The $search query option allows clients to request entities matching a free-text search expression. We add the data of all fields for all languages to a single field in the database and use this combined field to implement the full text search. * **$filter**: The $filter query option allows clients to filter a collection of resources that are addressed by a request URL. * **$orderby**: The $orderby query option allows clients to request resources in a particular order.  Read more about it at: https://docs.squidex.io/04-guides/02-api.html 
     * Queries About contents.
     */
    async queryAboutContentsRaw(requestParameters: QueryAboutContentsRequest): Promise<runtime.ApiResponse<InlineResponse2002>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.$search !== undefined) {
            queryParameters['$search'] = requestParameters.$search;
        }

        if (requestParameters.$top !== undefined) {
            queryParameters['$top'] = requestParameters.$top;
        }

        if (requestParameters.$skip !== undefined) {
            queryParameters['$skip'] = requestParameters.$skip;
        }

        if (requestParameters.$orderby !== undefined) {
            queryParameters['$orderby'] = requestParameters.$orderby;
        }

        if (requestParameters.$filter !== undefined) {
            queryParameters['$filter'] = requestParameters.$filter;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("squidex-oauth-auth", ["squidex.apps.fotoblazinec.contents.about.read"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/content/fotoblazinec/about/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2002FromJSON(jsonValue));
    }

    /**
     * The squidex API the OData url convention to query data.   We support the following query options.  * **$top**: The $top query option requests the number of items in the queried collection to be included in the result. The default value is 20 and the maximum allowed value is 200. * **$skip**: The $skip query option requests the number of items in the queried collection that are to be skipped and not included in the result. Use it together with $top to read the all your data page by page.  * **$search**: The $search query option allows clients to request entities matching a free-text search expression. We add the data of all fields for all languages to a single field in the database and use this combined field to implement the full text search. * **$filter**: The $filter query option allows clients to filter a collection of resources that are addressed by a request URL. * **$orderby**: The $orderby query option allows clients to request resources in a particular order.  Read more about it at: https://docs.squidex.io/04-guides/02-api.html 
     * Queries About contents.
     */
    async queryAboutContents(requestParameters: QueryAboutContentsRequest): Promise<InlineResponse2002> {
        const response = await this.queryAboutContentsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update a About content.
     */
    async updateAboutContentRaw(requestParameters: UpdateAboutContentRequest): Promise<runtime.ApiResponse<AboutContentDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateAboutContent.');
        }

        if (requestParameters.aboutDto === null || requestParameters.aboutDto === undefined) {
            throw new runtime.RequiredError('aboutDto','Required parameter requestParameters.aboutDto was null or undefined when calling updateAboutContent.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("squidex-oauth-auth", ["squidex.apps.fotoblazinec.contents.about.update"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/content/fotoblazinec/about/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AboutDtoToJSON(requestParameters.aboutDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AboutContentDtoFromJSON(jsonValue));
    }

    /**
     * Update a About content.
     */
    async updateAboutContent(requestParameters: UpdateAboutContentRequest): Promise<AboutContentDto> {
        const response = await this.updateAboutContentRaw(requestParameters);
        return await response.value();
    }

}
