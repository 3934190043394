/* tslint:disable */
/* eslint-disable */
/**
 * Squidex API for fotoblazinec App
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 4.0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ChangeStatusDto,
    ChangeStatusDtoFromJSON,
    ChangeStatusDtoToJSON,
    DocumentsContentDto,
    DocumentsContentDtoFromJSON,
    DocumentsContentDtoToJSON,
    DocumentsDto,
    DocumentsDtoFromJSON,
    DocumentsDtoToJSON,
    ErrorDto,
    ErrorDtoFromJSON,
    ErrorDtoToJSON,
    InlineResponse2003,
    InlineResponse2003FromJSON,
    InlineResponse2003ToJSON,
} from '../models';

export interface ChangeDocumentsContentStatusRequest {
    id: string;
    changeStatusDto: ChangeStatusDto;
}

export interface CreateDocumentsContentRequest {
    documentsDto: DocumentsDto;
    publish?: boolean;
}

export interface DeleteDocumentsContentRequest {
    id: string;
}

export interface GetDocumentsContentRequest {
    id: string;
}

export interface PathDocumentsContentRequest {
    id: string;
    documentsDto: DocumentsDto;
}

export interface QueryDocumentsContentsRequest {
    $search?: string;
    $top?: number;
    $skip?: number;
    $orderby?: string;
    $filter?: string;
}

export interface UpdateDocumentsContentRequest {
    id: string;
    documentsDto: DocumentsDto;
}

/**
 * no description
 */
export class DocumentsApi extends runtime.BaseAPI {

    /**
     * Change status of Documents content.
     */
    async changeDocumentsContentStatusRaw(requestParameters: ChangeDocumentsContentStatusRequest): Promise<runtime.ApiResponse<DocumentsContentDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling changeDocumentsContentStatus.');
        }

        if (requestParameters.changeStatusDto === null || requestParameters.changeStatusDto === undefined) {
            throw new runtime.RequiredError('changeStatusDto','Required parameter requestParameters.changeStatusDto was null or undefined when calling changeDocumentsContentStatus.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("squidex-oauth-auth", ["squidex.apps.fotoblazinec.contents.documents.update"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/content/fotoblazinec/documents/{id}/status`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ChangeStatusDtoToJSON(requestParameters.changeStatusDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DocumentsContentDtoFromJSON(jsonValue));
    }

    /**
     * Change status of Documents content.
     */
    async changeDocumentsContentStatus(requestParameters: ChangeDocumentsContentStatusRequest): Promise<DocumentsContentDto> {
        const response = await this.changeDocumentsContentStatusRaw(requestParameters);
        return await response.value();
    }

    /**
     * Create a Documents content.
     */
    async createDocumentsContentRaw(requestParameters: CreateDocumentsContentRequest): Promise<runtime.ApiResponse<DocumentsContentDto>> {
        if (requestParameters.documentsDto === null || requestParameters.documentsDto === undefined) {
            throw new runtime.RequiredError('documentsDto','Required parameter requestParameters.documentsDto was null or undefined when calling createDocumentsContent.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.publish !== undefined) {
            queryParameters['publish'] = requestParameters.publish;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("squidex-oauth-auth", ["squidex.apps.fotoblazinec.contents.documents.create"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/content/fotoblazinec/documents/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DocumentsDtoToJSON(requestParameters.documentsDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DocumentsContentDtoFromJSON(jsonValue));
    }

    /**
     * Create a Documents content.
     */
    async createDocumentsContent(requestParameters: CreateDocumentsContentRequest): Promise<DocumentsContentDto> {
        const response = await this.createDocumentsContentRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete a Documents content.
     */
    async deleteDocumentsContentRaw(requestParameters: DeleteDocumentsContentRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteDocumentsContent.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("squidex-oauth-auth", ["squidex.apps.fotoblazinec.contents.documents.delete"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/content/fotoblazinec/documents/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a Documents content.
     */
    async deleteDocumentsContent(requestParameters: DeleteDocumentsContentRequest): Promise<void> {
        await this.deleteDocumentsContentRaw(requestParameters);
    }

    /**
     * Get a Documents content.
     */
    async getDocumentsContentRaw(requestParameters: GetDocumentsContentRequest): Promise<runtime.ApiResponse<DocumentsContentDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getDocumentsContent.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("squidex-oauth-auth", ["squidex.apps.fotoblazinec.contents.documents.read"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/content/fotoblazinec/documents/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DocumentsContentDtoFromJSON(jsonValue));
    }

    /**
     * Get a Documents content.
     */
    async getDocumentsContent(requestParameters: GetDocumentsContentRequest): Promise<DocumentsContentDto> {
        const response = await this.getDocumentsContentRaw(requestParameters);
        return await response.value();
    }

    /**
     * Patch a Documents content.
     */
    async pathDocumentsContentRaw(requestParameters: PathDocumentsContentRequest): Promise<runtime.ApiResponse<DocumentsContentDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling pathDocumentsContent.');
        }

        if (requestParameters.documentsDto === null || requestParameters.documentsDto === undefined) {
            throw new runtime.RequiredError('documentsDto','Required parameter requestParameters.documentsDto was null or undefined when calling pathDocumentsContent.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("squidex-oauth-auth", ["squidex.apps.fotoblazinec.contents.documents.update"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/content/fotoblazinec/documents/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: DocumentsDtoToJSON(requestParameters.documentsDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DocumentsContentDtoFromJSON(jsonValue));
    }

    /**
     * Patch a Documents content.
     */
    async pathDocumentsContent(requestParameters: PathDocumentsContentRequest): Promise<DocumentsContentDto> {
        const response = await this.pathDocumentsContentRaw(requestParameters);
        return await response.value();
    }

    /**
     * The squidex API the OData url convention to query data.   We support the following query options.  * **$top**: The $top query option requests the number of items in the queried collection to be included in the result. The default value is 20 and the maximum allowed value is 200. * **$skip**: The $skip query option requests the number of items in the queried collection that are to be skipped and not included in the result. Use it together with $top to read the all your data page by page.  * **$search**: The $search query option allows clients to request entities matching a free-text search expression. We add the data of all fields for all languages to a single field in the database and use this combined field to implement the full text search. * **$filter**: The $filter query option allows clients to filter a collection of resources that are addressed by a request URL. * **$orderby**: The $orderby query option allows clients to request resources in a particular order.  Read more about it at: https://docs.squidex.io/04-guides/02-api.html 
     * Queries Documents contents.
     */
    async queryDocumentsContentsRaw(requestParameters: QueryDocumentsContentsRequest): Promise<runtime.ApiResponse<InlineResponse2003>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.$search !== undefined) {
            queryParameters['$search'] = requestParameters.$search;
        }

        if (requestParameters.$top !== undefined) {
            queryParameters['$top'] = requestParameters.$top;
        }

        if (requestParameters.$skip !== undefined) {
            queryParameters['$skip'] = requestParameters.$skip;
        }

        if (requestParameters.$orderby !== undefined) {
            queryParameters['$orderby'] = requestParameters.$orderby;
        }

        if (requestParameters.$filter !== undefined) {
            queryParameters['$filter'] = requestParameters.$filter;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("squidex-oauth-auth", ["squidex.apps.fotoblazinec.contents.documents.read"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/content/fotoblazinec/documents/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2003FromJSON(jsonValue));
    }

    /**
     * The squidex API the OData url convention to query data.   We support the following query options.  * **$top**: The $top query option requests the number of items in the queried collection to be included in the result. The default value is 20 and the maximum allowed value is 200. * **$skip**: The $skip query option requests the number of items in the queried collection that are to be skipped and not included in the result. Use it together with $top to read the all your data page by page.  * **$search**: The $search query option allows clients to request entities matching a free-text search expression. We add the data of all fields for all languages to a single field in the database and use this combined field to implement the full text search. * **$filter**: The $filter query option allows clients to filter a collection of resources that are addressed by a request URL. * **$orderby**: The $orderby query option allows clients to request resources in a particular order.  Read more about it at: https://docs.squidex.io/04-guides/02-api.html 
     * Queries Documents contents.
     */
    async queryDocumentsContents(requestParameters: QueryDocumentsContentsRequest): Promise<InlineResponse2003> {
        const response = await this.queryDocumentsContentsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update a Documents content.
     */
    async updateDocumentsContentRaw(requestParameters: UpdateDocumentsContentRequest): Promise<runtime.ApiResponse<DocumentsContentDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateDocumentsContent.');
        }

        if (requestParameters.documentsDto === null || requestParameters.documentsDto === undefined) {
            throw new runtime.RequiredError('documentsDto','Required parameter requestParameters.documentsDto was null or undefined when calling updateDocumentsContent.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("squidex-oauth-auth", ["squidex.apps.fotoblazinec.contents.documents.update"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/content/fotoblazinec/documents/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DocumentsDtoToJSON(requestParameters.documentsDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DocumentsContentDtoFromJSON(jsonValue));
    }

    /**
     * Update a Documents content.
     */
    async updateDocumentsContent(requestParameters: UpdateDocumentsContentRequest): Promise<DocumentsContentDto> {
        const response = await this.updateDocumentsContentRaw(requestParameters);
        return await response.value();
    }

}
