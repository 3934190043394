import React from 'react'
import { useQuery } from 'react-query'

import { aboutApi } from '../apis'
import LoadingWrapper from '../components/LoadingWrapper'
import Markdown from '../components/Markdown'
import { fetchSingle } from '../utils'

const HomePage = () => {
    const query = useQuery(
        'pages.about',
        fetchSingle(() => aboutApi.queryAboutContents({}))
    )

    return (
        <div>
            <LoadingWrapper query={query}>
                {(response) => <Markdown source={response.data.content.iv!} />}
            </LoadingWrapper>
        </div>
    )
}

export default HomePage
