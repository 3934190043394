/* tslint:disable */
/* eslint-disable */
/**
 * Squidex API for fotoblazinec App
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 4.0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ChangeStatusDto,
    ChangeStatusDtoFromJSON,
    ChangeStatusDtoToJSON,
    ErrorDto,
    ErrorDtoFromJSON,
    ErrorDtoToJSON,
    InlineResponse200,
    InlineResponse200FromJSON,
    InlineResponse200ToJSON,
    ScheduleContentDto,
    ScheduleContentDtoFromJSON,
    ScheduleContentDtoToJSON,
    ScheduleDto,
    ScheduleDtoFromJSON,
    ScheduleDtoToJSON,
} from '../models';

export interface ChangeScheduleContentStatusRequest {
    id: string;
    changeStatusDto: ChangeStatusDto;
}

export interface CreateScheduleContentRequest {
    scheduleDto: ScheduleDto;
    publish?: boolean;
}

export interface DeleteScheduleContentRequest {
    id: string;
}

export interface GetScheduleContentRequest {
    id: string;
}

export interface PathScheduleContentRequest {
    id: string;
    scheduleDto: ScheduleDto;
}

export interface QueryScheduleContentsRequest {
    $search?: string;
    $top?: number;
    $skip?: number;
    $orderby?: string;
    $filter?: string;
}

export interface UpdateScheduleContentRequest {
    id: string;
    scheduleDto: ScheduleDto;
}

/**
 * no description
 */
export class ScheduleApi extends runtime.BaseAPI {

    /**
     * Change status of Schedule content.
     */
    async changeScheduleContentStatusRaw(requestParameters: ChangeScheduleContentStatusRequest): Promise<runtime.ApiResponse<ScheduleContentDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling changeScheduleContentStatus.');
        }

        if (requestParameters.changeStatusDto === null || requestParameters.changeStatusDto === undefined) {
            throw new runtime.RequiredError('changeStatusDto','Required parameter requestParameters.changeStatusDto was null or undefined when calling changeScheduleContentStatus.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("squidex-oauth-auth", ["squidex.apps.fotoblazinec.contents.schedule.update"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/content/fotoblazinec/schedule/{id}/status`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ChangeStatusDtoToJSON(requestParameters.changeStatusDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ScheduleContentDtoFromJSON(jsonValue));
    }

    /**
     * Change status of Schedule content.
     */
    async changeScheduleContentStatus(requestParameters: ChangeScheduleContentStatusRequest): Promise<ScheduleContentDto> {
        const response = await this.changeScheduleContentStatusRaw(requestParameters);
        return await response.value();
    }

    /**
     * Create a Schedule content.
     */
    async createScheduleContentRaw(requestParameters: CreateScheduleContentRequest): Promise<runtime.ApiResponse<ScheduleContentDto>> {
        if (requestParameters.scheduleDto === null || requestParameters.scheduleDto === undefined) {
            throw new runtime.RequiredError('scheduleDto','Required parameter requestParameters.scheduleDto was null or undefined when calling createScheduleContent.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.publish !== undefined) {
            queryParameters['publish'] = requestParameters.publish;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("squidex-oauth-auth", ["squidex.apps.fotoblazinec.contents.schedule.create"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/content/fotoblazinec/schedule/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ScheduleDtoToJSON(requestParameters.scheduleDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ScheduleContentDtoFromJSON(jsonValue));
    }

    /**
     * Create a Schedule content.
     */
    async createScheduleContent(requestParameters: CreateScheduleContentRequest): Promise<ScheduleContentDto> {
        const response = await this.createScheduleContentRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete a Schedule content.
     */
    async deleteScheduleContentRaw(requestParameters: DeleteScheduleContentRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteScheduleContent.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("squidex-oauth-auth", ["squidex.apps.fotoblazinec.contents.schedule.delete"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/content/fotoblazinec/schedule/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a Schedule content.
     */
    async deleteScheduleContent(requestParameters: DeleteScheduleContentRequest): Promise<void> {
        await this.deleteScheduleContentRaw(requestParameters);
    }

    /**
     * Get a Schedule content.
     */
    async getScheduleContentRaw(requestParameters: GetScheduleContentRequest): Promise<runtime.ApiResponse<ScheduleContentDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getScheduleContent.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("squidex-oauth-auth", ["squidex.apps.fotoblazinec.contents.schedule.read"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/content/fotoblazinec/schedule/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ScheduleContentDtoFromJSON(jsonValue));
    }

    /**
     * Get a Schedule content.
     */
    async getScheduleContent(requestParameters: GetScheduleContentRequest): Promise<ScheduleContentDto> {
        const response = await this.getScheduleContentRaw(requestParameters);
        return await response.value();
    }

    /**
     * Patch a Schedule content.
     */
    async pathScheduleContentRaw(requestParameters: PathScheduleContentRequest): Promise<runtime.ApiResponse<ScheduleContentDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling pathScheduleContent.');
        }

        if (requestParameters.scheduleDto === null || requestParameters.scheduleDto === undefined) {
            throw new runtime.RequiredError('scheduleDto','Required parameter requestParameters.scheduleDto was null or undefined when calling pathScheduleContent.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("squidex-oauth-auth", ["squidex.apps.fotoblazinec.contents.schedule.update"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/content/fotoblazinec/schedule/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ScheduleDtoToJSON(requestParameters.scheduleDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ScheduleContentDtoFromJSON(jsonValue));
    }

    /**
     * Patch a Schedule content.
     */
    async pathScheduleContent(requestParameters: PathScheduleContentRequest): Promise<ScheduleContentDto> {
        const response = await this.pathScheduleContentRaw(requestParameters);
        return await response.value();
    }

    /**
     * The squidex API the OData url convention to query data.   We support the following query options.  * **$top**: The $top query option requests the number of items in the queried collection to be included in the result. The default value is 20 and the maximum allowed value is 200. * **$skip**: The $skip query option requests the number of items in the queried collection that are to be skipped and not included in the result. Use it together with $top to read the all your data page by page.  * **$search**: The $search query option allows clients to request entities matching a free-text search expression. We add the data of all fields for all languages to a single field in the database and use this combined field to implement the full text search. * **$filter**: The $filter query option allows clients to filter a collection of resources that are addressed by a request URL. * **$orderby**: The $orderby query option allows clients to request resources in a particular order.  Read more about it at: https://docs.squidex.io/04-guides/02-api.html 
     * Queries Schedule contents.
     */
    async queryScheduleContentsRaw(requestParameters: QueryScheduleContentsRequest): Promise<runtime.ApiResponse<InlineResponse200>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.$search !== undefined) {
            queryParameters['$search'] = requestParameters.$search;
        }

        if (requestParameters.$top !== undefined) {
            queryParameters['$top'] = requestParameters.$top;
        }

        if (requestParameters.$skip !== undefined) {
            queryParameters['$skip'] = requestParameters.$skip;
        }

        if (requestParameters.$orderby !== undefined) {
            queryParameters['$orderby'] = requestParameters.$orderby;
        }

        if (requestParameters.$filter !== undefined) {
            queryParameters['$filter'] = requestParameters.$filter;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("squidex-oauth-auth", ["squidex.apps.fotoblazinec.contents.schedule.read"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/content/fotoblazinec/schedule/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse200FromJSON(jsonValue));
    }

    /**
     * The squidex API the OData url convention to query data.   We support the following query options.  * **$top**: The $top query option requests the number of items in the queried collection to be included in the result. The default value is 20 and the maximum allowed value is 200. * **$skip**: The $skip query option requests the number of items in the queried collection that are to be skipped and not included in the result. Use it together with $top to read the all your data page by page.  * **$search**: The $search query option allows clients to request entities matching a free-text search expression. We add the data of all fields for all languages to a single field in the database and use this combined field to implement the full text search. * **$filter**: The $filter query option allows clients to filter a collection of resources that are addressed by a request URL. * **$orderby**: The $orderby query option allows clients to request resources in a particular order.  Read more about it at: https://docs.squidex.io/04-guides/02-api.html 
     * Queries Schedule contents.
     */
    async queryScheduleContents(requestParameters: QueryScheduleContentsRequest): Promise<InlineResponse200> {
        const response = await this.queryScheduleContentsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update a Schedule content.
     */
    async updateScheduleContentRaw(requestParameters: UpdateScheduleContentRequest): Promise<runtime.ApiResponse<ScheduleContentDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateScheduleContent.');
        }

        if (requestParameters.scheduleDto === null || requestParameters.scheduleDto === undefined) {
            throw new runtime.RequiredError('scheduleDto','Required parameter requestParameters.scheduleDto was null or undefined when calling updateScheduleContent.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("squidex-oauth-auth", ["squidex.apps.fotoblazinec.contents.schedule.update"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/content/fotoblazinec/schedule/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ScheduleDtoToJSON(requestParameters.scheduleDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ScheduleContentDtoFromJSON(jsonValue));
    }

    /**
     * Update a Schedule content.
     */
    async updateScheduleContent(requestParameters: UpdateScheduleContentRequest): Promise<ScheduleContentDto> {
        const response = await this.updateScheduleContentRaw(requestParameters);
        return await response.value();
    }

}
