import React from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router'

import { authorApi, editionApi } from '../apis'
import AuthorList from '../components/AuthorList'
import LoadingWrapper from '../components/LoadingWrapper'
import { fetchSingle } from '../utils'

const EditionPage = () => {
    const params = useParams<{ uri: string }>()
    const editionQuery = useQuery(
        `edition.${params.uri}`,
        fetchSingle(() =>
            editionApi.queryEditionContents({
                $filter: `data/uri/iv eq '${params.uri}'`,
            })
        )
    )

    const authorsQuery = useQuery(
        `authors.${params.uri}`,
        () =>
            authorApi.queryAuthorContents({
                $filter: `data/edition/iv eq '${editionQuery.data!.id}'`,
            }),
        { enabled: editionQuery.data }
    )

    return (
        <LoadingWrapper query={authorsQuery}>
            {(authors) => (
                <>
                    <h1>{editionQuery.data!.data.title.iv}</h1>
                    <AuthorList authors={authors.items} />
                </>
            )}
        </LoadingWrapper>
    )
}

export default EditionPage
