import React from 'react'
import { useQuery } from 'react-query'

import { documentsApi } from '../apis'
import LoadingWrapper from '../components/LoadingWrapper'
import Markdown from '../components/Markdown'
import { fetchSingle } from '../utils'

const DocumentsPage = () => {
    const query = useQuery(
        'pages.documents',
        fetchSingle(() => documentsApi.queryDocumentsContents({}))
    )

    return (
        <LoadingWrapper query={query}>
            {(result) => <Markdown source={result.data.content.iv!} />}
        </LoadingWrapper>
    )
}

export default DocumentsPage
