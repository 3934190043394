import React from 'react'
import { Spinner } from 'react-bootstrap'
import { QueryResult } from 'react-query'

interface Props<T> {
    children: (data: T) => JSX.Element
    query: QueryResult<T>
}

const LoadingWrapper = <T extends {}>({ query, children }: Props<T>) => {
    if (query.isError) {
        return <>Data loading failed</>
    }

    if (query.data === undefined) {
        return <Spinner animation="border" />
    }

    return children(query.data)
}

export default LoadingWrapper
