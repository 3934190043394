import {
    AboutApi,
    AuthorApi,
    Configuration,
    ContactApi,
    DocumentsApi,
    EditionApi,
    HTTPHeaders,
    ScheduleApi,
} from './generatedApis'

const AUTH_KEY = 'auth'

const config = new Configuration({
    basePath: `${process.env.REACT_APP_API_URL}/api`,
    middleware: [
        {
            pre: async (context) => {
                let token = window.localStorage.getItem(AUTH_KEY)

                if (token === null) {
                    const body = Object.entries({
                        grant_type: 'client_credentials',
                        client_id: process.env.REACT_APP_API_CLIENT_ID!,
                        client_secret: process.env.REACT_APP_API_CLIENT_SECRET!,
                        scope: 'squidex-api',
                    })
                        .map(
                            ([k, v]) =>
                                `${encodeURIComponent(k)}=${encodeURIComponent(
                                    v
                                )}`
                        )
                        .join('&')

                    const response = await fetch(
                        `${process.env.REACT_APP_API_URL}/identity-server/connect/token`,
                        {
                            method: 'POST',
                            body,
                            headers: {
                                'Content-Type':
                                    'application/x-www-form-urlencoded',
                            },
                        }
                    )
                    const json = await response.json()
                    token = json['access_token']

                    window.localStorage.setItem(AUTH_KEY, token!)
                }

                const headers: HTTPHeaders | undefined = context.init
                    .headers as HTTPHeaders
                headers['Authorization'] = `Bearer ${token}`
            },
            post: async (context) => {
                if (context.response.status === 401) {
                    window.localStorage.removeItem(AUTH_KEY)
                }
            },
        },
    ],
})

export const aboutApi = new AboutApi(config)
export const scheduleApi = new ScheduleApi(config)
export const documentsApi = new DocumentsApi(config)
export const contactApi = new ContactApi(config)
export const editionApi = new EditionApi(config)
export const authorApi = new AuthorApi(config)
