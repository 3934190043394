/* tslint:disable */
/* eslint-disable */
/**
 * Squidex API for fotoblazinec App
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 4.0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EditionDateProperty,
    EditionDatePropertyFromJSON,
    EditionDatePropertyFromJSONTyped,
    EditionDatePropertyToJSON,
    EditionTitleProperty,
    EditionTitlePropertyFromJSON,
    EditionTitlePropertyFromJSONTyped,
    EditionTitlePropertyToJSON,
    EditionUriProperty,
    EditionUriPropertyFromJSON,
    EditionUriPropertyFromJSONTyped,
    EditionUriPropertyToJSON,
} from './';

/**
 * 
 * @export
 * @interface EditionDto
 */
export interface EditionDto {
    /**
     * 
     * @type {EditionTitleProperty}
     * @memberof EditionDto
     */
    title: EditionTitleProperty;
    /**
     * 
     * @type {EditionUriProperty}
     * @memberof EditionDto
     */
    uri: EditionUriProperty;
    /**
     * 
     * @type {EditionDateProperty}
     * @memberof EditionDto
     */
    date: EditionDateProperty;
}

export function EditionDtoFromJSON(json: any): EditionDto {
    return EditionDtoFromJSONTyped(json, false);
}

export function EditionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EditionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': EditionTitlePropertyFromJSON(json['title']),
        'uri': EditionUriPropertyFromJSON(json['uri']),
        'date': EditionDatePropertyFromJSON(json['date']),
    };
}

export function EditionDtoToJSON(value?: EditionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': EditionTitlePropertyToJSON(value.title),
        'uri': EditionUriPropertyToJSON(value.uri),
        'date': EditionDatePropertyToJSON(value.date),
    };
}


