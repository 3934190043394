/* tslint:disable */
/* eslint-disable */
/**
 * Squidex API for fotoblazinec App
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 4.0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EditionDateProperty
 */
export interface EditionDateProperty {
    /**
     * Invariant
     * @type {Date}
     * @memberof EditionDateProperty
     */
    iv: Date | null;
}

export function EditionDatePropertyFromJSON(json: any): EditionDateProperty {
    return EditionDatePropertyFromJSONTyped(json, false);
}

export function EditionDatePropertyFromJSONTyped(json: any, ignoreDiscriminator: boolean): EditionDateProperty {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'iv': (json['iv'] === null ? null : new Date(json['iv'])),
    };
}

export function EditionDatePropertyToJSON(value?: EditionDateProperty | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'iv': (value.iv === null ? null : value.iv.toISOString()),
    };
}


