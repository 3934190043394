import React, { useMemo, useState } from 'react'
import Lightbox from 'react-image-lightbox'

import { AuthorContentDto } from '../generatedApis/models'

import AuthorBlock from './AuthorBlock'

interface Props {
    authors: AuthorContentDto[]
}

const AuthorList: React.FC<Props> = ({ authors }) => {
    const [lightboxIndex, setLightBoxIndex] = useState<number | undefined>(
        undefined
    )

    const images = useMemo(
        () =>
            authors.flatMap((a) =>
                a.data.photos.iv!.map((id) => ({
                    src: `${process.env.REACT_APP_API_URL}/api/assets/fotoblazinec/${id}`,
                    title: a.data.name.iv!,
                }))
            ),
        [authors]
    )

    return (
        <>
            {lightboxIndex !== undefined && (
                <Lightbox
                    imageTitle={images[lightboxIndex].title}
                    mainSrc={images[lightboxIndex].src}
                    nextSrc={images[(lightboxIndex + 1) % images.length].src}
                    prevSrc={
                        images[
                            (lightboxIndex - 1 + images.length) % images.length
                        ].src
                    }
                    onCloseRequest={() => setLightBoxIndex(undefined)}
                    onMoveNextRequest={() =>
                        setLightBoxIndex((lightboxIndex + 1) % images.length)
                    }
                    onMovePrevRequest={() =>
                        setLightBoxIndex(
                            (lightboxIndex - 1 + images.length) % images.length
                        )
                    }
                />
            )}
            {authors.map((author) => (
                <AuthorBlock
                    key={author.id}
                    author={author}
                    onSelect={(url) =>
                        setLightBoxIndex(images.findIndex((i) => i.src === url))
                    }
                />
            ))}
        </>
    )
}

export default AuthorList
