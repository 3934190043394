/* tslint:disable */
/* eslint-disable */
/**
 * Squidex API for fotoblazinec App
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 4.0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ChangeStatusDto,
    ChangeStatusDtoFromJSON,
    ChangeStatusDtoToJSON,
    ContactContentDto,
    ContactContentDtoFromJSON,
    ContactContentDtoToJSON,
    ContactDto,
    ContactDtoFromJSON,
    ContactDtoToJSON,
    ErrorDto,
    ErrorDtoFromJSON,
    ErrorDtoToJSON,
    InlineResponse2001,
    InlineResponse2001FromJSON,
    InlineResponse2001ToJSON,
} from '../models';

export interface ChangeContactContentStatusRequest {
    id: string;
    changeStatusDto: ChangeStatusDto;
}

export interface CreateContactContentRequest {
    contactDto: ContactDto;
    publish?: boolean;
}

export interface DeleteContactContentRequest {
    id: string;
}

export interface GetContactContentRequest {
    id: string;
}

export interface PathContactContentRequest {
    id: string;
    contactDto: ContactDto;
}

export interface QueryContactContentsRequest {
    $search?: string;
    $top?: number;
    $skip?: number;
    $orderby?: string;
    $filter?: string;
}

export interface UpdateContactContentRequest {
    id: string;
    contactDto: ContactDto;
}

/**
 * no description
 */
export class ContactApi extends runtime.BaseAPI {

    /**
     * Change status of Contact content.
     */
    async changeContactContentStatusRaw(requestParameters: ChangeContactContentStatusRequest): Promise<runtime.ApiResponse<ContactContentDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling changeContactContentStatus.');
        }

        if (requestParameters.changeStatusDto === null || requestParameters.changeStatusDto === undefined) {
            throw new runtime.RequiredError('changeStatusDto','Required parameter requestParameters.changeStatusDto was null or undefined when calling changeContactContentStatus.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("squidex-oauth-auth", ["squidex.apps.fotoblazinec.contents.contact.update"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/content/fotoblazinec/contact/{id}/status`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ChangeStatusDtoToJSON(requestParameters.changeStatusDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ContactContentDtoFromJSON(jsonValue));
    }

    /**
     * Change status of Contact content.
     */
    async changeContactContentStatus(requestParameters: ChangeContactContentStatusRequest): Promise<ContactContentDto> {
        const response = await this.changeContactContentStatusRaw(requestParameters);
        return await response.value();
    }

    /**
     * Create a Contact content.
     */
    async createContactContentRaw(requestParameters: CreateContactContentRequest): Promise<runtime.ApiResponse<ContactContentDto>> {
        if (requestParameters.contactDto === null || requestParameters.contactDto === undefined) {
            throw new runtime.RequiredError('contactDto','Required parameter requestParameters.contactDto was null or undefined when calling createContactContent.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.publish !== undefined) {
            queryParameters['publish'] = requestParameters.publish;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("squidex-oauth-auth", ["squidex.apps.fotoblazinec.contents.contact.create"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/content/fotoblazinec/contact/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ContactDtoToJSON(requestParameters.contactDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ContactContentDtoFromJSON(jsonValue));
    }

    /**
     * Create a Contact content.
     */
    async createContactContent(requestParameters: CreateContactContentRequest): Promise<ContactContentDto> {
        const response = await this.createContactContentRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete a Contact content.
     */
    async deleteContactContentRaw(requestParameters: DeleteContactContentRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteContactContent.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("squidex-oauth-auth", ["squidex.apps.fotoblazinec.contents.contact.delete"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/content/fotoblazinec/contact/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a Contact content.
     */
    async deleteContactContent(requestParameters: DeleteContactContentRequest): Promise<void> {
        await this.deleteContactContentRaw(requestParameters);
    }

    /**
     * Get a Contact content.
     */
    async getContactContentRaw(requestParameters: GetContactContentRequest): Promise<runtime.ApiResponse<ContactContentDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getContactContent.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("squidex-oauth-auth", ["squidex.apps.fotoblazinec.contents.contact.read"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/content/fotoblazinec/contact/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ContactContentDtoFromJSON(jsonValue));
    }

    /**
     * Get a Contact content.
     */
    async getContactContent(requestParameters: GetContactContentRequest): Promise<ContactContentDto> {
        const response = await this.getContactContentRaw(requestParameters);
        return await response.value();
    }

    /**
     * Patch a Contact content.
     */
    async pathContactContentRaw(requestParameters: PathContactContentRequest): Promise<runtime.ApiResponse<ContactContentDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling pathContactContent.');
        }

        if (requestParameters.contactDto === null || requestParameters.contactDto === undefined) {
            throw new runtime.RequiredError('contactDto','Required parameter requestParameters.contactDto was null or undefined when calling pathContactContent.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("squidex-oauth-auth", ["squidex.apps.fotoblazinec.contents.contact.update"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/content/fotoblazinec/contact/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ContactDtoToJSON(requestParameters.contactDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ContactContentDtoFromJSON(jsonValue));
    }

    /**
     * Patch a Contact content.
     */
    async pathContactContent(requestParameters: PathContactContentRequest): Promise<ContactContentDto> {
        const response = await this.pathContactContentRaw(requestParameters);
        return await response.value();
    }

    /**
     * The squidex API the OData url convention to query data.   We support the following query options.  * **$top**: The $top query option requests the number of items in the queried collection to be included in the result. The default value is 20 and the maximum allowed value is 200. * **$skip**: The $skip query option requests the number of items in the queried collection that are to be skipped and not included in the result. Use it together with $top to read the all your data page by page.  * **$search**: The $search query option allows clients to request entities matching a free-text search expression. We add the data of all fields for all languages to a single field in the database and use this combined field to implement the full text search. * **$filter**: The $filter query option allows clients to filter a collection of resources that are addressed by a request URL. * **$orderby**: The $orderby query option allows clients to request resources in a particular order.  Read more about it at: https://docs.squidex.io/04-guides/02-api.html 
     * Queries Contact contents.
     */
    async queryContactContentsRaw(requestParameters: QueryContactContentsRequest): Promise<runtime.ApiResponse<InlineResponse2001>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.$search !== undefined) {
            queryParameters['$search'] = requestParameters.$search;
        }

        if (requestParameters.$top !== undefined) {
            queryParameters['$top'] = requestParameters.$top;
        }

        if (requestParameters.$skip !== undefined) {
            queryParameters['$skip'] = requestParameters.$skip;
        }

        if (requestParameters.$orderby !== undefined) {
            queryParameters['$orderby'] = requestParameters.$orderby;
        }

        if (requestParameters.$filter !== undefined) {
            queryParameters['$filter'] = requestParameters.$filter;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("squidex-oauth-auth", ["squidex.apps.fotoblazinec.contents.contact.read"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/content/fotoblazinec/contact/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2001FromJSON(jsonValue));
    }

    /**
     * The squidex API the OData url convention to query data.   We support the following query options.  * **$top**: The $top query option requests the number of items in the queried collection to be included in the result. The default value is 20 and the maximum allowed value is 200. * **$skip**: The $skip query option requests the number of items in the queried collection that are to be skipped and not included in the result. Use it together with $top to read the all your data page by page.  * **$search**: The $search query option allows clients to request entities matching a free-text search expression. We add the data of all fields for all languages to a single field in the database and use this combined field to implement the full text search. * **$filter**: The $filter query option allows clients to filter a collection of resources that are addressed by a request URL. * **$orderby**: The $orderby query option allows clients to request resources in a particular order.  Read more about it at: https://docs.squidex.io/04-guides/02-api.html 
     * Queries Contact contents.
     */
    async queryContactContents(requestParameters: QueryContactContentsRequest): Promise<InlineResponse2001> {
        const response = await this.queryContactContentsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update a Contact content.
     */
    async updateContactContentRaw(requestParameters: UpdateContactContentRequest): Promise<runtime.ApiResponse<ContactContentDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateContactContent.');
        }

        if (requestParameters.contactDto === null || requestParameters.contactDto === undefined) {
            throw new runtime.RequiredError('contactDto','Required parameter requestParameters.contactDto was null or undefined when calling updateContactContent.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("squidex-oauth-auth", ["squidex.apps.fotoblazinec.contents.contact.update"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/content/fotoblazinec/contact/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ContactDtoToJSON(requestParameters.contactDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ContactContentDtoFromJSON(jsonValue));
    }

    /**
     * Update a Contact content.
     */
    async updateContactContent(requestParameters: UpdateContactContentRequest): Promise<ContactContentDto> {
        const response = await this.updateContactContentRaw(requestParameters);
        return await response.value();
    }

}
