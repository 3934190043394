/* tslint:disable */
/* eslint-disable */
/**
 * Squidex API for fotoblazinec App
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 4.0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AuthorContentDto,
    AuthorContentDtoFromJSON,
    AuthorContentDtoToJSON,
    AuthorDto,
    AuthorDtoFromJSON,
    AuthorDtoToJSON,
    ChangeStatusDto,
    ChangeStatusDtoFromJSON,
    ChangeStatusDtoToJSON,
    ErrorDto,
    ErrorDtoFromJSON,
    ErrorDtoToJSON,
    InlineResponse2005,
    InlineResponse2005FromJSON,
    InlineResponse2005ToJSON,
} from '../models';

export interface ChangeAuthorContentStatusRequest {
    id: string;
    changeStatusDto: ChangeStatusDto;
}

export interface CreateAuthorContentRequest {
    authorDto: AuthorDto;
    publish?: boolean;
}

export interface DeleteAuthorContentRequest {
    id: string;
}

export interface GetAuthorContentRequest {
    id: string;
}

export interface PathAuthorContentRequest {
    id: string;
    authorDto: AuthorDto;
}

export interface QueryAuthorContentsRequest {
    $search?: string;
    $top?: number;
    $skip?: number;
    $orderby?: string;
    $filter?: string;
}

export interface UpdateAuthorContentRequest {
    id: string;
    authorDto: AuthorDto;
}

/**
 * no description
 */
export class AuthorApi extends runtime.BaseAPI {

    /**
     * Change status of Author content.
     */
    async changeAuthorContentStatusRaw(requestParameters: ChangeAuthorContentStatusRequest): Promise<runtime.ApiResponse<AuthorContentDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling changeAuthorContentStatus.');
        }

        if (requestParameters.changeStatusDto === null || requestParameters.changeStatusDto === undefined) {
            throw new runtime.RequiredError('changeStatusDto','Required parameter requestParameters.changeStatusDto was null or undefined when calling changeAuthorContentStatus.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("squidex-oauth-auth", ["squidex.apps.fotoblazinec.contents.author.update"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/content/fotoblazinec/author/{id}/status`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ChangeStatusDtoToJSON(requestParameters.changeStatusDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthorContentDtoFromJSON(jsonValue));
    }

    /**
     * Change status of Author content.
     */
    async changeAuthorContentStatus(requestParameters: ChangeAuthorContentStatusRequest): Promise<AuthorContentDto> {
        const response = await this.changeAuthorContentStatusRaw(requestParameters);
        return await response.value();
    }

    /**
     * Create a Author content.
     */
    async createAuthorContentRaw(requestParameters: CreateAuthorContentRequest): Promise<runtime.ApiResponse<AuthorContentDto>> {
        if (requestParameters.authorDto === null || requestParameters.authorDto === undefined) {
            throw new runtime.RequiredError('authorDto','Required parameter requestParameters.authorDto was null or undefined when calling createAuthorContent.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.publish !== undefined) {
            queryParameters['publish'] = requestParameters.publish;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("squidex-oauth-auth", ["squidex.apps.fotoblazinec.contents.author.create"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/content/fotoblazinec/author/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AuthorDtoToJSON(requestParameters.authorDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthorContentDtoFromJSON(jsonValue));
    }

    /**
     * Create a Author content.
     */
    async createAuthorContent(requestParameters: CreateAuthorContentRequest): Promise<AuthorContentDto> {
        const response = await this.createAuthorContentRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete a Author content.
     */
    async deleteAuthorContentRaw(requestParameters: DeleteAuthorContentRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteAuthorContent.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("squidex-oauth-auth", ["squidex.apps.fotoblazinec.contents.author.delete"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/content/fotoblazinec/author/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a Author content.
     */
    async deleteAuthorContent(requestParameters: DeleteAuthorContentRequest): Promise<void> {
        await this.deleteAuthorContentRaw(requestParameters);
    }

    /**
     * Get a Author content.
     */
    async getAuthorContentRaw(requestParameters: GetAuthorContentRequest): Promise<runtime.ApiResponse<AuthorContentDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getAuthorContent.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("squidex-oauth-auth", ["squidex.apps.fotoblazinec.contents.author.read"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/content/fotoblazinec/author/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthorContentDtoFromJSON(jsonValue));
    }

    /**
     * Get a Author content.
     */
    async getAuthorContent(requestParameters: GetAuthorContentRequest): Promise<AuthorContentDto> {
        const response = await this.getAuthorContentRaw(requestParameters);
        return await response.value();
    }

    /**
     * Patch a Author content.
     */
    async pathAuthorContentRaw(requestParameters: PathAuthorContentRequest): Promise<runtime.ApiResponse<AuthorContentDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling pathAuthorContent.');
        }

        if (requestParameters.authorDto === null || requestParameters.authorDto === undefined) {
            throw new runtime.RequiredError('authorDto','Required parameter requestParameters.authorDto was null or undefined when calling pathAuthorContent.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("squidex-oauth-auth", ["squidex.apps.fotoblazinec.contents.author.update"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/content/fotoblazinec/author/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: AuthorDtoToJSON(requestParameters.authorDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthorContentDtoFromJSON(jsonValue));
    }

    /**
     * Patch a Author content.
     */
    async pathAuthorContent(requestParameters: PathAuthorContentRequest): Promise<AuthorContentDto> {
        const response = await this.pathAuthorContentRaw(requestParameters);
        return await response.value();
    }

    /**
     * The squidex API the OData url convention to query data.   We support the following query options.  * **$top**: The $top query option requests the number of items in the queried collection to be included in the result. The default value is 20 and the maximum allowed value is 200. * **$skip**: The $skip query option requests the number of items in the queried collection that are to be skipped and not included in the result. Use it together with $top to read the all your data page by page.  * **$search**: The $search query option allows clients to request entities matching a free-text search expression. We add the data of all fields for all languages to a single field in the database and use this combined field to implement the full text search. * **$filter**: The $filter query option allows clients to filter a collection of resources that are addressed by a request URL. * **$orderby**: The $orderby query option allows clients to request resources in a particular order.  Read more about it at: https://docs.squidex.io/04-guides/02-api.html 
     * Queries Author contents.
     */
    async queryAuthorContentsRaw(requestParameters: QueryAuthorContentsRequest): Promise<runtime.ApiResponse<InlineResponse2005>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.$search !== undefined) {
            queryParameters['$search'] = requestParameters.$search;
        }

        if (requestParameters.$top !== undefined) {
            queryParameters['$top'] = requestParameters.$top;
        }

        if (requestParameters.$skip !== undefined) {
            queryParameters['$skip'] = requestParameters.$skip;
        }

        if (requestParameters.$orderby !== undefined) {
            queryParameters['$orderby'] = requestParameters.$orderby;
        }

        if (requestParameters.$filter !== undefined) {
            queryParameters['$filter'] = requestParameters.$filter;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("squidex-oauth-auth", ["squidex.apps.fotoblazinec.contents.author.read"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/content/fotoblazinec/author/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2005FromJSON(jsonValue));
    }

    /**
     * The squidex API the OData url convention to query data.   We support the following query options.  * **$top**: The $top query option requests the number of items in the queried collection to be included in the result. The default value is 20 and the maximum allowed value is 200. * **$skip**: The $skip query option requests the number of items in the queried collection that are to be skipped and not included in the result. Use it together with $top to read the all your data page by page.  * **$search**: The $search query option allows clients to request entities matching a free-text search expression. We add the data of all fields for all languages to a single field in the database and use this combined field to implement the full text search. * **$filter**: The $filter query option allows clients to filter a collection of resources that are addressed by a request URL. * **$orderby**: The $orderby query option allows clients to request resources in a particular order.  Read more about it at: https://docs.squidex.io/04-guides/02-api.html 
     * Queries Author contents.
     */
    async queryAuthorContents(requestParameters: QueryAuthorContentsRequest): Promise<InlineResponse2005> {
        const response = await this.queryAuthorContentsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update a Author content.
     */
    async updateAuthorContentRaw(requestParameters: UpdateAuthorContentRequest): Promise<runtime.ApiResponse<AuthorContentDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateAuthorContent.');
        }

        if (requestParameters.authorDto === null || requestParameters.authorDto === undefined) {
            throw new runtime.RequiredError('authorDto','Required parameter requestParameters.authorDto was null or undefined when calling updateAuthorContent.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("squidex-oauth-auth", ["squidex.apps.fotoblazinec.contents.author.update"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/content/fotoblazinec/author/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AuthorDtoToJSON(requestParameters.authorDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthorContentDtoFromJSON(jsonValue));
    }

    /**
     * Update a Author content.
     */
    async updateAuthorContent(requestParameters: UpdateAuthorContentRequest): Promise<AuthorContentDto> {
        const response = await this.updateAuthorContentRaw(requestParameters);
        return await response.value();
    }

}
