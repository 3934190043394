import React from 'react'
import { useQuery } from 'react-query'

import { authorApi, editionApi } from '../apis'
import AuthorList from '../components/AuthorList'
import LoadingWrapper from '../components/LoadingWrapper'

const AuthorsPage = () => {
    const currentEditionQuery = useQuery('editions.current', async () => {
        const editions = await editionApi.queryEditionContents({
            $orderby: 'data/date/iv desc',
            $top: 1,
        })

        return editions.items[0]
    })

    const authorsQuery = useQuery(
        `authors.${currentEditionQuery.data?.id}`,
        () =>
            authorApi.queryAuthorContents({
                $filter: `data/edition/iv eq '${currentEditionQuery.data?.id}'`,
            }),
        { enabled: currentEditionQuery.data }
    )

    return (
        <>
            <h1>Autoři</h1>
            <LoadingWrapper query={authorsQuery}>
                {(authorsResponse) => (
                    <>
                        {authorsResponse.total > 0 ? (
                            <AuthorList authors={authorsResponse.items} />
                        ) : (
                            <p className="lead">
                                Zde mohou být vaše fotky. Zatím se nikdo
                                nepřihlásil.
                            </p>
                        )}
                    </>
                )}
            </LoadingWrapper>
        </>
    )
}

export default AuthorsPage
