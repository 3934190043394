import React from 'react'
import ReactMarkdown from 'react-markdown'

import styles from '../common.module.scss'

const Markdown: React.FC<{ source: string }> = ({ source }) => (
    <ReactMarkdown className={styles.richTextContent} source={source} />
)

export default Markdown
