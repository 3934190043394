import React from 'react'
import { useQuery } from 'react-query'

import { scheduleApi } from '../apis'
import LoadingWrapper from '../components/LoadingWrapper'
import Markdown from '../components/Markdown'
import { fetchSingle } from '../utils'

const SchedulePage = () => {
    const query = useQuery(
        'pages.schedule',
        fetchSingle(() => scheduleApi.queryScheduleContents({}))
    )

    return (
        <LoadingWrapper query={query}>
            {(result) => <Markdown source={result.data.content.iv!} />}
        </LoadingWrapper>
    )
}

export default SchedulePage
