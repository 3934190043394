/* tslint:disable */
/* eslint-disable */
/**
 * Squidex API for fotoblazinec App
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 4.0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ChangeStatusDto
 */
export interface ChangeStatusDto {
    /**
     * The new status.
     * @type {string}
     * @memberof ChangeStatusDto
     */
    status: string;
    /**
     * The due time.
     * @type {Date}
     * @memberof ChangeStatusDto
     */
    dueTime?: Date;
}

export function ChangeStatusDtoFromJSON(json: any): ChangeStatusDto {
    return ChangeStatusDtoFromJSONTyped(json, false);
}

export function ChangeStatusDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangeStatusDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': json['Status'],
        'dueTime': !exists(json, 'DueTime') ? undefined : (new Date(json['DueTime'])),
    };
}

export function ChangeStatusDtoToJSON(value?: ChangeStatusDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Status': value.status,
        'DueTime': value.dueTime === undefined ? undefined : (value.dueTime.toISOString()),
    };
}


