/* tslint:disable */
/* eslint-disable */
/**
 * Squidex API for fotoblazinec App
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 4.0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ChangeStatusDto,
    ChangeStatusDtoFromJSON,
    ChangeStatusDtoToJSON,
    EditionContentDto,
    EditionContentDtoFromJSON,
    EditionContentDtoToJSON,
    EditionDto,
    EditionDtoFromJSON,
    EditionDtoToJSON,
    ErrorDto,
    ErrorDtoFromJSON,
    ErrorDtoToJSON,
    InlineResponse2004,
    InlineResponse2004FromJSON,
    InlineResponse2004ToJSON,
} from '../models';

export interface ChangeEditionContentStatusRequest {
    id: string;
    changeStatusDto: ChangeStatusDto;
}

export interface CreateEditionContentRequest {
    editionDto: EditionDto;
    publish?: boolean;
}

export interface DeleteEditionContentRequest {
    id: string;
}

export interface GetEditionContentRequest {
    id: string;
}

export interface PathEditionContentRequest {
    id: string;
    editionDto: EditionDto;
}

export interface QueryEditionContentsRequest {
    $search?: string;
    $top?: number;
    $skip?: number;
    $orderby?: string;
    $filter?: string;
}

export interface UpdateEditionContentRequest {
    id: string;
    editionDto: EditionDto;
}

/**
 * no description
 */
export class EditionApi extends runtime.BaseAPI {

    /**
     * Change status of Edition content.
     */
    async changeEditionContentStatusRaw(requestParameters: ChangeEditionContentStatusRequest): Promise<runtime.ApiResponse<EditionContentDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling changeEditionContentStatus.');
        }

        if (requestParameters.changeStatusDto === null || requestParameters.changeStatusDto === undefined) {
            throw new runtime.RequiredError('changeStatusDto','Required parameter requestParameters.changeStatusDto was null or undefined when calling changeEditionContentStatus.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("squidex-oauth-auth", ["squidex.apps.fotoblazinec.contents.edition.update"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/content/fotoblazinec/edition/{id}/status`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ChangeStatusDtoToJSON(requestParameters.changeStatusDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EditionContentDtoFromJSON(jsonValue));
    }

    /**
     * Change status of Edition content.
     */
    async changeEditionContentStatus(requestParameters: ChangeEditionContentStatusRequest): Promise<EditionContentDto> {
        const response = await this.changeEditionContentStatusRaw(requestParameters);
        return await response.value();
    }

    /**
     * Create a Edition content.
     */
    async createEditionContentRaw(requestParameters: CreateEditionContentRequest): Promise<runtime.ApiResponse<EditionContentDto>> {
        if (requestParameters.editionDto === null || requestParameters.editionDto === undefined) {
            throw new runtime.RequiredError('editionDto','Required parameter requestParameters.editionDto was null or undefined when calling createEditionContent.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.publish !== undefined) {
            queryParameters['publish'] = requestParameters.publish;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("squidex-oauth-auth", ["squidex.apps.fotoblazinec.contents.edition.create"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/content/fotoblazinec/edition/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EditionDtoToJSON(requestParameters.editionDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EditionContentDtoFromJSON(jsonValue));
    }

    /**
     * Create a Edition content.
     */
    async createEditionContent(requestParameters: CreateEditionContentRequest): Promise<EditionContentDto> {
        const response = await this.createEditionContentRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete a Edition content.
     */
    async deleteEditionContentRaw(requestParameters: DeleteEditionContentRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteEditionContent.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("squidex-oauth-auth", ["squidex.apps.fotoblazinec.contents.edition.delete"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/content/fotoblazinec/edition/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a Edition content.
     */
    async deleteEditionContent(requestParameters: DeleteEditionContentRequest): Promise<void> {
        await this.deleteEditionContentRaw(requestParameters);
    }

    /**
     * Get a Edition content.
     */
    async getEditionContentRaw(requestParameters: GetEditionContentRequest): Promise<runtime.ApiResponse<EditionContentDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getEditionContent.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("squidex-oauth-auth", ["squidex.apps.fotoblazinec.contents.edition.read"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/content/fotoblazinec/edition/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EditionContentDtoFromJSON(jsonValue));
    }

    /**
     * Get a Edition content.
     */
    async getEditionContent(requestParameters: GetEditionContentRequest): Promise<EditionContentDto> {
        const response = await this.getEditionContentRaw(requestParameters);
        return await response.value();
    }

    /**
     * Patch a Edition content.
     */
    async pathEditionContentRaw(requestParameters: PathEditionContentRequest): Promise<runtime.ApiResponse<EditionContentDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling pathEditionContent.');
        }

        if (requestParameters.editionDto === null || requestParameters.editionDto === undefined) {
            throw new runtime.RequiredError('editionDto','Required parameter requestParameters.editionDto was null or undefined when calling pathEditionContent.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("squidex-oauth-auth", ["squidex.apps.fotoblazinec.contents.edition.update"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/content/fotoblazinec/edition/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: EditionDtoToJSON(requestParameters.editionDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EditionContentDtoFromJSON(jsonValue));
    }

    /**
     * Patch a Edition content.
     */
    async pathEditionContent(requestParameters: PathEditionContentRequest): Promise<EditionContentDto> {
        const response = await this.pathEditionContentRaw(requestParameters);
        return await response.value();
    }

    /**
     * The squidex API the OData url convention to query data.   We support the following query options.  * **$top**: The $top query option requests the number of items in the queried collection to be included in the result. The default value is 20 and the maximum allowed value is 200. * **$skip**: The $skip query option requests the number of items in the queried collection that are to be skipped and not included in the result. Use it together with $top to read the all your data page by page.  * **$search**: The $search query option allows clients to request entities matching a free-text search expression. We add the data of all fields for all languages to a single field in the database and use this combined field to implement the full text search. * **$filter**: The $filter query option allows clients to filter a collection of resources that are addressed by a request URL. * **$orderby**: The $orderby query option allows clients to request resources in a particular order.  Read more about it at: https://docs.squidex.io/04-guides/02-api.html 
     * Queries Edition contents.
     */
    async queryEditionContentsRaw(requestParameters: QueryEditionContentsRequest): Promise<runtime.ApiResponse<InlineResponse2004>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.$search !== undefined) {
            queryParameters['$search'] = requestParameters.$search;
        }

        if (requestParameters.$top !== undefined) {
            queryParameters['$top'] = requestParameters.$top;
        }

        if (requestParameters.$skip !== undefined) {
            queryParameters['$skip'] = requestParameters.$skip;
        }

        if (requestParameters.$orderby !== undefined) {
            queryParameters['$orderby'] = requestParameters.$orderby;
        }

        if (requestParameters.$filter !== undefined) {
            queryParameters['$filter'] = requestParameters.$filter;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("squidex-oauth-auth", ["squidex.apps.fotoblazinec.contents.edition.read"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/content/fotoblazinec/edition/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2004FromJSON(jsonValue));
    }

    /**
     * The squidex API the OData url convention to query data.   We support the following query options.  * **$top**: The $top query option requests the number of items in the queried collection to be included in the result. The default value is 20 and the maximum allowed value is 200. * **$skip**: The $skip query option requests the number of items in the queried collection that are to be skipped and not included in the result. Use it together with $top to read the all your data page by page.  * **$search**: The $search query option allows clients to request entities matching a free-text search expression. We add the data of all fields for all languages to a single field in the database and use this combined field to implement the full text search. * **$filter**: The $filter query option allows clients to filter a collection of resources that are addressed by a request URL. * **$orderby**: The $orderby query option allows clients to request resources in a particular order.  Read more about it at: https://docs.squidex.io/04-guides/02-api.html 
     * Queries Edition contents.
     */
    async queryEditionContents(requestParameters: QueryEditionContentsRequest): Promise<InlineResponse2004> {
        const response = await this.queryEditionContentsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update a Edition content.
     */
    async updateEditionContentRaw(requestParameters: UpdateEditionContentRequest): Promise<runtime.ApiResponse<EditionContentDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateEditionContent.');
        }

        if (requestParameters.editionDto === null || requestParameters.editionDto === undefined) {
            throw new runtime.RequiredError('editionDto','Required parameter requestParameters.editionDto was null or undefined when calling updateEditionContent.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("squidex-oauth-auth", ["squidex.apps.fotoblazinec.contents.edition.update"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/content/fotoblazinec/edition/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: EditionDtoToJSON(requestParameters.editionDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EditionContentDtoFromJSON(jsonValue));
    }

    /**
     * Update a Edition content.
     */
    async updateEditionContent(requestParameters: UpdateEditionContentRequest): Promise<EditionContentDto> {
        const response = await this.updateEditionContentRaw(requestParameters);
        return await response.value();
    }

}
