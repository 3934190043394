/* tslint:disable */
/* eslint-disable */
/**
 * Squidex API for fotoblazinec App
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 4.0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AuthorPhotosProperty
 */
export interface AuthorPhotosProperty {
    /**
     * Invariant
     * @type {Array<string>}
     * @memberof AuthorPhotosProperty
     */
    iv: Array<string> | null;
}

export function AuthorPhotosPropertyFromJSON(json: any): AuthorPhotosProperty {
    return AuthorPhotosPropertyFromJSONTyped(json, false);
}

export function AuthorPhotosPropertyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthorPhotosProperty {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'iv': json['iv'],
    };
}

export function AuthorPhotosPropertyToJSON(value?: AuthorPhotosProperty | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'iv': value.iv,
    };
}


