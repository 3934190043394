export const fetchSingle = <T extends {}>(
    fetchFn: () => Promise<{ items: T[] }>
) => async () => {
    const response = await fetchFn()

    if (response.items.length === 0) {
        throw new Error('Query returned an empty result')
    }

    if (response.items.length > 1) {
        throw new Error('Query returned multiple results')
    }

    return response.items[0]
}
